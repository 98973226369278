import { Outlet } from 'react-router-dom'
import { useLayoutEffect } from 'react'
import { gsap } from 'gsap';

const GlobalAnimations = () => {

	useLayoutEffect(() => {

		let ctx = gsap.context(() => {
			
			//! mouse 
			const mouse = document.querySelector('.mouse')
			const cursor = document.querySelector('.cursor')

			//* hover elements
			function createHoverElements() {
				const cursorHover = gsap.to(cursor, {duration: 0.333, width: 40, height: 40, opacity: 0.4, ease: 'power1.inOut', paused: true})

				const hoverElements = document.querySelectorAll('a, .hoverElement, label, select, input, textarea, button')
				hoverElements.forEach(hover => {
					hover.addEventListener('mouseenter', function(){
						cursorHover.play()
					})
					hover.addEventListener('mouseleave', function(){
						cursorHover.reverse()
					})
				})

				cursorHover.reverse()
			}

			if(!window.matchMedia('(pointer: coarse)').matches) {
				gsap.set(mouse, {display: 'block'})

				//* hover elements
				createHoverElements()

				//* move mouse
				function moveMouse(e) {
					gsap.set(mouse, {
						left: e.clientX,
						top: e.clientY
					})
				}

				document.addEventListener('mousemove', moveMouse)

				//* hide/show mouse on page leave/enter
				document.addEventListener('mouseleave', function(){
					gsap.set(mouse, {opacity: 0})
				})
				
				document.addEventListener('mouseenter', function(){
					gsap.set(mouse, {opacity: 1})
				})
			}
		})

		return () => ctx.revert()

	}, [])

	return (
		<>
			<div className="mouse">
				<div className="cursor"></div>
			</div>
			<Outlet />
		</>
	)
}

export default GlobalAnimations;