import { storyblokEditable } from "@storyblok/react"
import { useLayoutEffect, useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import Player from '@vimeo/player'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)
gsap.registerPlugin(ScrollTrigger)

const SpotLight = ({ blok }) => {
	const { vimeoId } = blok
	
	const playerRef = useRef(null)
	const playerInstance = useRef(null)
	const [isLooping, setIsLooping] = useState(true)

	const videoLoopStart = 0
	const videoLoopDuration = videoLoopStart + 15

	useEffect(() => {
		playerInstance.current = new Player(playerRef.current, {
			id: vimeoId,
			muted: true,
			controls: false,
			autoplay: true,
		})
		
		const player = playerInstance.current
		
		player.on('timeupdate', (event) => {
			if (isLooping && event.seconds >= videoLoopDuration) {
				player.setCurrentTime(videoLoopStart).then(() => player.play())
			}
		})
		
		player.on('ended', () => {
			if (!isLooping) {
				setIsLooping(true)
				player.setCurrentTime(videoLoopStart).then(() => player.play())
				player.setMuted(true)
			}
		})
		
		return () => {
			player.unload()
		}
	}, [vimeoId, videoLoopDuration, videoLoopStart])

	useEffect(() => {
		const player = playerInstance.current

		const playReel = gsap.timeline({ paused: true })

		playReel
			.fromTo('.spotlight .overlay', { opacity: 1 }, { duration: .667, opacity: 0, ease: 'none' }, 0)
			.fromTo('.spotlight .video', { width: '60vw', height: '70vh' }, { duration: .667, width: 'calc(100vw - 4vmin)', height: 'calc(100vh - 4vmin)', ease: 'power3.inOut' }, 0)
		;
		
		if (isLooping) {
			player.setMuted(true)
			player.getCurrentTime().then((seconds) => {
				if (seconds < videoLoopDuration) {
					player.setCurrentTime(videoLoopStart).then(() => player.play())
				}
			})
			playReel.reverse(1)
			gsap.set('body', {overflow: 'auto'})
		} else {
			player.setMuted(false)
			player.setCurrentTime(0).then(() => player.play())
			playReel.play()
			gsap.to(window, { duration: 0.3, scrollTo: '.spotlight' })
			gsap.set('body', {overflow: 'hidden'})
		}
	}, [isLooping, videoLoopStart, videoLoopDuration])
		
	const handleButtonClick = () => {
		setIsLooping(!isLooping)

		playerInstance.current.on('loaded', () => {
			console.log('Video is loaded.')
		})
	}

	useEffect(() => {
		let ctx = gsap.context(() => {
			const fadeVideo = gsap.timeline({
				scrollTrigger: {
					trigger: '.spotlight',
					toggleActions: 'play play play reverse',
					start: 'center center',
					end: '15% top',
					markers: false,
					id: 'spotlight',
					scrub: 1,
				},
			})

			fadeVideo
				.fromTo('.spotlight .video', { opacity: 0 }, { duration: 1, opacity: 1, ease: 'none' }, 0)
				.fromTo('.spotlight', { scale: 0.7 }, { duration: 1, scale: 1, ease: 'none' }, 0)
				.fromTo('.spotlight .text', { opacity: 0 }, { duration: .667, opacity: 1, ease: 'none' }, 0.5)
				.set('.spotlight .video', { pointerEvents: 'auto' }, '0.75')
			;
		})

		return () => ctx.revert()
	}, [])

	useEffect(() => {
		let ctx = gsap.context(() => {
			const spotlightPin = gsap.timeline({
				scrollTrigger: {
					trigger: '.spotlight',
					start: 'center center',
					end: '15% top',
					markers: false,
					id: 'spotlight-pin',
					pin: true,
					anticipatePin: true,
				},
			})
		})

		return () => ctx.revert()
	}, [])

	return (
		<div className="spotlight" {...storyblokEditable(blok)}>
			<div onClick={handleButtonClick} className="video hoverElement" id="video" ref={playerRef}>
				<div className='overlay'>
					<div className='background b1'></div>
					<div className="text tac flex-column align-center">
						<span className="text-small ttupper">Click to</span>
						<span className="t5 m-t-8">Watch full reel</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SpotLight
