const Hero = ({ blok }) => {

	return (
		<footer className='flex-column align-center p-b-128'>
			<span className='t5'>GET IN TOUCH</span>
			<a className='mail m-t-56' href='mailto:hello@davidgamper.com' title='mail'>hello@davidgamper.com</a>
		</footer>
	);
}

export default Hero;