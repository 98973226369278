import React, { useRef, useEffect, useState, useMemo, useLayoutEffect } from 'react'
import { gsap } from 'gsap'
import * as THREE from 'three'
import { useFrame, useThree } from '@react-three/fiber'
import { Image, Text } from '@react-three/drei'
import Player from '@vimeo/player'
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Experience = ({ blok }) => {

	const { media } = blok
	const { camera } = useThree()

	const imageRefs = useRef([]);

	if (imageRefs.current.length !== media.length) {
		imageRefs.current = Array(media.length).fill().map((_, i) => imageRefs.current[i] || React.createRef())
	}

	gsap.registerPlugin(ScrollTrigger);

	useEffect(() => {
		let ctx = gsap.context(() => {
			const zoomCamera = gsap.timeline({
				scrollTrigger: {
					trigger: '.showcase',
					toggleActions: 'play reverse play reverse',
					start: 'top top',
					end: `200% 50%`,
					markers: false,
					scrub: 1,
					pin: true,
					id: 'experience',
				}
			})

			zoomCamera
				.to(camera.position, {duration: 1, z: 0.3, ease: 'none'}, 0)
				.from(imageRefs.current.map(ref => ref.current.material), { grayscale: 1, duration: 0.2, delay: i => 0.025 * i, ease: 'none'}, 0)
				// .to(imageRefs.current.map(ref => ref.current.material), { opacity: 1, duration: 0.025, stagger: 0.025, ease: 'none'}, 0)
			;
		})

		ScrollTrigger.refresh();

		return () => ctx.revert()
	}, [camera])

	const calculateAspectRatio = (src) => {
		const regex = /\/(\d+)x(\d+)\//
		const match = src.match(regex)
		const width = parseInt(match[1], 10)
		const height = parseInt(match[2], 10)
		const aspectRatio =  height / width

		return aspectRatio.toFixed(2)
	}

	useFrame((state, delta) => {
	})

	function generateAxisPosition(index) {
		// Determine the quadrant based on the index
		const normalizedIndex = (index % 4 === 0) ? 4 : index % 4;
	
		switch (normalizedIndex) {
			case 1: // Top left (x: [-1, -0.5], y: [0.5, 1])
				return {
					x: Math.random() * (-0.5 - (-1)) + (-1),
					y: Math.random() * (1 - 0.5) + 0.5
				};
			case 2: // Bottom right (x: [0.5, 1], y: [-1, -0.5])
				return {
					x: Math.random() * (1 - 0.5) + 0.5,
					y: Math.random() * (-0.5 - (-1)) + (-1)
				};
			case 3: // Top right (x: [0.5, 1], y: [0.5, 1])
				return {
					x: Math.random() * (1 - 0.5) + 0.5,
					y: Math.random() * (1 - 0.5) + 0.5
				};
			case 4: // Bottom left (x: [-1, -0.5], y: [-1, -0.5])
				return {
					x: Math.random() * (-0.5 - (-1)) + (-1),
					y: Math.random() * (-0.5 - (-1)) + (-1)
				};
			default:
				return { x: 0, y: 0 }; // fallback, should not occur
		}
	}

	const planeImages = useMemo(() => {
		const planeImages = []
	
		media.map((mediaEl, index) => {
			const imagePath = mediaEl.filename.replace('a.storyblok.com', 'a2.storyblok.com');
			const aspect = calculateAspectRatio(imagePath);
	
			const { x, y } = generateAxisPosition(index + 1);
	
			const planeImage = {
				imageSrc: `${imagePath}/m/${2000}x${2000 * aspect}/smart/filters:quality(${70})`,
				aspect: aspect,
				xAxis: x,
				yAxis: y
			};
	
			return planeImages.push(planeImage)
		});
	
		return planeImages
	}, [media]);

	return (
		<>
			{planeImages.map((planeImage, index) => {

				const { imageSrc, aspect, xAxis, yAxis } = planeImage

				return (
					<>
						<Image ref={imageRefs.current[index]} transparent={true} opacity={0.95} url={imageSrc} key={index} toneMapped={false} position={[xAxis , yAxis, (media.length - index) * 0.2]}>
							<planeGeometry 
								args={[1, 1 * aspect]}
							/>
						</Image>
					</>
				)
			})}
		</>
	)
}

export default Experience