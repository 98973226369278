import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useEffect, useLayoutEffect, useRef } from "react";
import GlobalAnimations from '../animations/GlobalAnimations'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import Footer from './Footer'
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const Page = ({ blok, loaded }) => {

	const componentRef = useRef(null)

	useEffect(() => {

		let ctx = gsap.context(() => {

			let smoother = ScrollSmoother.create({
				smooth: 1,
				smoothTouch: 0.1,
				effects: true
			})

			const formButton = document.querySelector('.formButton')

			if (formButton) {
				formButton.addEventListener('click', () => {
					gsap.to(smoother, { scrollTop: Math.min(ScrollTrigger.maxScroll(window), smoother.offset('#form', 'top 200px')), duration: 1})
				})
			}

		}, componentRef)

		return () => ctx.revert()

	}, [])


	return (
		<>
			<main {...storyblokEditable(blok)}>
				<div id="smooth-wrapper" ref={componentRef}>
					<div id="smooth-content">
						{blok.content
						? blok.content.map((blok) => (
							<StoryblokComponent blok={blok} key={blok._uid} />
							))
						: null}
						<Footer/>
					</div>
				</div>
			</main>
			<GlobalAnimations />
		</>
	)
};
 
export default Page;