import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { StrictMode } from 'react';

import { storyblokInit, apiPlugin } from '@storyblok/react';

import Page from './components/Page';
import Hero from './components/Hero';
import Content from './components/Content'
import Showcase from './components/showcase/Showcase'
import Spotlight from './components/spotlight/Spotlight'

storyblokInit({
	accessToken: process.env.REACT_APP_STORYBLOK_API_TOKEN,
	use: [apiPlugin],
	components: {
		page: Page,
		hero: Hero,
		content: Content,
		showcase: Showcase,
		spotlight: Spotlight
	},
	apiOptions: {
		region: ''
	}
});

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
	<StrictMode>
		<App />
	</StrictMode>
);