import { useStoryblok, StoryblokComponent } from "@storyblok/react"

import './styles/spacing/space.scss'
import './styles/01-default.css'
import './styles/02-styleguide.css'
import './styles/03-main.css'

function App() {
	let slug =
		window.location.pathname === "/"
		? "home"
		: window.location.pathname.replace("/", "");

	const story = useStoryblok(slug, { version: "draft" });

	if (!story || !story.content) {
		return
	}

	if (typeof window !== 'undefined' && window.innerHeight > window.innerWidth) {
		window.screen.orientation.lock('portrait')
	}

	return (
		<StoryblokComponent blok={story.content} />
	)
}
export default App;