import { useRef, useEffect, useState } from "react";

export default function ImageOptim({image, quality, className}) {

	console.log(image)

	let {alt, filename} = image
	let qualityVal = quality ? quality : 70

	const pictureRef = useRef();
	const [pictureSize, setPictureSize] = useState(10+'x'+10)

	useEffect( () => {
		const width = pictureRef.current.offsetWidth
		const height = pictureRef.current.offsetHeight

		setPictureSize(width+'x'+height)
	}, [])


	return (
		<picture ref={pictureRef} className= {className}>
			<source srcSet={filename + `/m/${pictureSize}/filters:format(avif):quality(${qualityVal})`} type="image/avif" />
			<source srcSet={filename  + `/m/${pictureSize}/filters:format(avif):quality(${qualityVal})`} type="image/webp" />
			<img src={filename + `/m/${pictureSize}/filters:quality(${qualityVal})`} alt={alt ? alt : ''} />
		</picture>
	)
}