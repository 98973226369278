import { storyblokEditable } from "@storyblok/react";
import { useLayoutEffect, useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import { SplitText } from 'gsap/dist/SplitText'
import Player from '@vimeo/player'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ImageOptim from "./subcomponents/ImageOptim";

const Hero = ({ blok }) => {

	const { vimeoId, thumbnail } = blok

	const videoRef = useRef(null);
	const playerRef = useRef(null);

	const [ initiateExperience, setInitiateExperience ] = useState(false)

	gsap.registerPlugin(ScrollTrigger);

	useEffect(() => {
		if (initiateExperience) {
			const player = new Player(videoRef.current, {
				id: parseInt(vimeoId),
				background: true,
				autoplay: true,
				muted: true,
				loop: true
			})

			playerRef.current = player
		
			player.on('playing', () => {
				const thumbnailElement = document.querySelector('.hero .thumbnail');
				if (thumbnailElement) {
					gsap.to('.hero .thumbnail', {  duration: .333, opacity: 0, easer: 'none' })
				}
			})

			return () => {
				player.destroy()
			}
		}
	}, [vimeoId, initiateExperience])

	useEffect(() => {

		let ctx = gsap.context(() => {

			let mm = gsap.matchMedia()

			const hero = document.querySelector('.hero')

			//! loader 

			mm.add('(min-width: 800px)', () => {
				const textBreathe = gsap.timeline({ repeat: -1, yoyo: true, repeatDelay: 1.6, delay: 3});

				const firstText = new SplitText('.firstname', { type: 'words, chars' });
				const lastText = new SplitText('.surname', { type: 'words, chars' });

				textBreathe
					.from(firstText.chars, { duration: 0.8, fontWeight: 250, stagger: 0.2 }, 0)
					.from(lastText.chars, { duration: 0.8, fontWeight: 250, stagger: 0.2 }, 0)
				;

				const initialLoader = gsap.timeline({ paused: true, delay: 0.2 })

				initialLoader
					.from('.hero .name', {duration: 1, y: '10vh', ease: 'power3.inOut'}, 0)
					.from('.hero .name', {duration: 1, opacity: 0, ease: 'none'}, 0)
					.to('.firstname', { duration: .667, marginRight: '4vmin', ease: 'power3.inOut'})
					.from(['.hero .videoWrap', '.hero .bottom'], {duration: 1, stagger: 0.2, opacity: 0, ease: 'none'}, '>')
				;
	
				initialLoader.play()

				hero.addEventListener('click', () => {
					if (!initiateExperience) {
						const startExperience = gsap.timeline({ paused: true })

						startExperience
							.to('.hero .videoWrap', { duration: 1, width: '90%', height: '90%', 'filter': 'grayscale(0)', ease: 'power3.inOut' }, 0)
							.to('.hero .bottom', {duration: .333, opacity: 0, ease: 'none', overwrite: 'auto' }, 0)
							.to('.hero .firstname', { duration: 1, marginRight: '2vmin', overwrite: 'auto', ease: 'power3.inOut' }, 0)
							.to('.hero .top', { duration: 1, top: '18vh', opacity: 0.6, overwrite: 'auto', ease: 'power3.inOut' }, 1)
							.to('.hero .name', { duration: 1, fontSize: '6vmin', ease: 'power3.inOut', overwrite: 'auto' }, 1)
							.to('.hero .slogan', { duration: 1, opacity: 1, ease: 'none' }, 2)
						;

						startExperience.play()
						document.querySelector('body').classList.remove('loading')
						setInitiateExperience(true)
					}
				})
			})
			mm.add('(max-width: 799px)', () => {
				const startExperience = gsap.timeline({ paused: true })

				console.log('test')

				startExperience
					.to('.hero .firstname', { duration: 1, marginRight: '2vmin', overwrite: 'auto', ease: 'power3.inOut' }, 0)
					.to('.hero .top', { duration: 1, top: '18vh', opacity: 0.6, overwrite: 'auto', ease: 'power3.inOut' }, 1)
					.to('.hero .name', { duration: 1, fontSize: '6vmin', ease: 'power3.inOut', overwrite: 'auto' }, 1)
					.to('.hero .slogan', { duration: 1, opacity: 1, ease: 'none' }, 2)
				;

				startExperience.play()
				document.querySelector('body').classList.remove('loading')
				setInitiateExperience(true)
			})
		})

		return () => ctx.revert()

	}, [])

	return (
		<div className='hero' {...storyblokEditable(blok)}>
			<div className='top flex-column align-center tac'>
				<div className='name flex'>
					<div className='firstname'>DAVID</div>
					<div className='surname'>GAMPER</div>
				</div>
				<div className='slogan t5 m-t-32'>Visual Storyteller</div>
			</div>
			<div className='bottom flex-column align-center'>
					<button className='t5'>Click to explore</button>
					<div className='coordinates flex font-small'>
						<div className='longitude'>46.6617° N</div>
						<div className='latitude'>11.1433° E</div>
					</div>
				</div>
			<div className='videoWrap'>
				<div className='thumbnail coverImage'>
					<ImageOptim image={thumbnail} sizes='(max-width: 100vw) 100vw, 100vh' images={thumbnail} />
				</div>
				<div className='video' id='video' ref={videoRef}></div>
			</div>
		</div>
	);
}

export default Hero;