import { Canvas } from '@react-three/fiber'
import { Image, Text } from '@react-three/drei'

import Experience from './Experience'

import { storyblokEditable } from "@storyblok/react"

const Showcase = ({ blok }) => {

	const { media } = blok

	return (
		<>
			<div className='showcase' {...storyblokEditable(blok)}>
				<Canvas camera={ { fov: 90, near: 0.1, far: 10, position: [ 0, 0, (media.length * 0.2) + 1 ] } }>
					<Experience blok={blok}></Experience>
				</Canvas>
			</div>
		</>
	)
}

export default Showcase