import { getStoryblokApi, storyblokEditable } from '@storyblok/react';
import { gsap } from 'gsap'
import { useRef, useLayoutEffect} from 'react'
import { SplitText } from 'gsap/dist/SplitText'

const Content = ({ blok }) => {

	const { title, text } = blok

	const contentRef = useRef(null)

	useLayoutEffect(() => {

		let ctx = gsap.context(() => {
			const charsFade = gsap.timeline({
				scrollTrigger: {
					trigger: contentRef.current,
					toggleActions: 'play reverse play reverse',
					start: 'top 50%',
					end: `100% 50%`,
					markers: false,
					scrub: 1,
					id: 'charsFade',
				}
			})

			const mySplitText = new SplitText(contentRef.current, { type: 'words, lines' })
			const chars = mySplitText.words

			charsFade
				.from(chars, { duration: 0.8, opacity: 0.4, stagger: 0.2, ease: 'none' })
			;
		})
		
		return () => ctx.revert()

	}, [])

	return (
		<div className='content m-tb-160' key={blok._uid} {...storyblokEditable(blok)}>
			<div className='center1420 tac flex-column'>
				<h1 className='t1'>{title}</h1>
				{text.content[0].content ? <div ref={contentRef} className='m-t-24 font-medium' dangerouslySetInnerHTML={{__html: getStoryblokApi().richTextResolver.render(text)}} /> : ''}
			</div>
		</div>
  	)
}

export default Content